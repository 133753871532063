import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

function SearchForm() {
  let { search } = useLocation();
  const params = new URLSearchParams(search);
  const [q, setQ] = useState("");
  useEffect(() => {
    if (params.get("q") !== "") setQ(params.get("q"));
  }, []);
  return (
    <div className="header-search header-search-extended header-search-visible d-none d-lg-block">
      <form action="search" method="get">
        <div className="header-search-wrapper search-wrapper-wide">
          <label htmlFor="q" className="sr-only">
            Search
          </label>
          <input
            type="search"
            className="form-control"
            name="q"
            id="q"
            placeholder="Search product..."
            required
            onChange={(e) => setQ(e.target.value)}
            value={q}
          />
          <Link to="#" className="search-toggle" role="button">
            <i className="icon-search"></i>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default React.memo(SearchForm);
