import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
// import Countdown from "react-countdown";
// import { useTranslation } from "react-i18next";

// import { rendererThree } from "./count-down";

import { findIndex } from "../../utils";

function ProductNine(props) {
//   const { t, i18n } = useTranslation("common");
  const {
    product,
    isWishlist,
    onAddToCart,
    onToggleWishlist,
    showQuickView,
    onAddToCompare,
  } = props;

  const addToCartHandler = () => {
    if (0 !== product.stock) onAddToCart(product, 1);
  };

  const wishlistHandler = () => {
    if (isWishlist) {
      window.location = process.env.PUBLIC_URL + "/shop/wishlist";
    } else {
      onToggleWishlist(product, isWishlist);
    }
  };

  const quickViewHandler = () => {
    showQuickView(product.id);
  };

  const addToCompareHandler = () => {
    onAddToCompare(product);
  };

  return product ? (
    <div className="product">
      <figure className="product-media">
        {product.badges.length > 0 &&
          product.badges.map((badge) => (
            <span
              className="product-label"
              style={{ color: "#fff", backgroundColor: badge.color }}
            >
              {badge.name}
            </span>
          ))}

        <Link to={`/product/${product.id}`}>
          <LazyLoadImage
            style={{ width: "100%", height: "auto" }}
            alt="product"
            src={product.image}
            threshold={100}
          />
        </Link>

        {/* {0 < product.discount ? (
          <div className="product-countdown countdown-compact">
            <Countdown date={`2021-02-01T01:02:03`} renderer={rendererThree} />
          </div>
        ) : (
          ""
        )} */}

        <div className="product-action-vertical">
          <button
            className={`btn-product-icon btn-wishlist btn-expandable ${
              isWishlist
                ? ("added-to-wishlist")
                : ("remove-from-wishlist")
            }`}
            onClick={wishlistHandler}
            title={
              isWishlist
                ? ("go_to_wishlist")
                : ("add_to_wishlist")
            }
          >
            <span>
              {isWishlist
                ? ("go_to_wishlist")
                : ("add_to_wishlist")}
            </span>
          </button>

          {/* <button className="btn-product-icon btn-quickview" title="Quick view" onClick={ quickViewHandler }>
                            <span>Quick view</span>
                        </button> */}

          {/* <button className="btn-product-icon btn-compare" title="Compare" onClick={ addToCompareHandler }>
                            <span>Compare</span>
                        </button> */}
        </div>

        <div className="product-action">
          <button
            className="btn-product btn-cart"
            title="add to cart"
            onClick={addToCartHandler}
          >
            <span> {("add_to_cart")}</span>
          </button>
        </div>
      </figure>

      <div className="product-body">
        <div className="product-cat">
          <span className="mr-0">
            <Link to="#">{product.category[0]}</Link>
          </span>
        </div>

        <h3 className="product-title">
          <Link to={`/product/${product.id}`}>{product.name}</Link>
        </h3>

        <div className="product-price">
          {localStorage.getItem("currency")} {product.price}
        </div>

        <div className="ratings-container">
          <div className="ratings">
            <div
              className="ratings-val"
              style={{ width: product.ratings * 20 + "%" }}
            ></div>
          </div>
          <span className="ratings-text">{product.reviews}</span>
        </div>

        {product.variants ? (
          product.variants[0].model ? (
            <div className="product-nav product-nav-thumbs">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={`vari_${i}`}
                  className={0 === i ? "active" : ""}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/" + vari.model}
                    alt="product desc"
                  />
                </Link>
              ))}
            </div>
          ) : (
            <div className="product-nav product-nav-dots">
              {product.variants.map((vari, i) => (
                <Link
                  to="#"
                  key={`vari_${i}`}
                  className={0 === i ? "active" : ""}
                  style={{ background: vari.color }}
                ></Link>
              ))}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    </div>
  ) : (
    ""
  );
}

function mapStateToProps(state, ownprops) {
  return {
    isWishlist:
      findIndex(
        state.wishlist.list,
        (item) => item.id === ownprops.product.id
      ) !== -1
        ? true
        : false,
  };
}

export default connect(mapStateToProps)(ProductNine);
