import { GET_BANNERS } from "../constants/action-types";

let initialState = {};
export const bannersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BANNERS:
      return {
        ...state,
        banners: action.payload,
      };
    default:
      return state;
  }
};

export default bannersReducer;
