import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

// import utils
import { safeContent } from "../../utils";

export default function Banner(props) {
  const {
    image,
    name,
    // text = "",
    // width = "300",
    // height = "",
    link
  } = props.data;
  return (
    <div>
      <Link to="/shop/sidebar/list">
        {/* <div className="lazy-overlay"></div> */}

        <LazyLoadImage
          alt="banner"
          src={image}
          threshold={100}
        //   width={width + "px"}
        //   height={height + "px"}
          effect="blur"
          height="408px"
          width="613px"
        />
      </Link>

      <div className="intro" style={{position:"absolute",top:"50px",left:"30px"}}>
        <div
          className="title"
          dangerouslySetInnerHTML={safeContent(name)}
        ></div>

        {/* <div
          className="content"
          dangerouslySetInnerHTML={safeContent(name)}
        ></div> */}

        {/* <p dangerouslySetInnerHTML={safeContent(text)}></p> */}

        <div className="" style={{color:"red"}}>
          <Link to={`${process.env.PUBLIC_URL}/shop/sidebar/list`}>
            {link}
            <i className="icon-long-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
