import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "react-app-polyfill/ie11";
import "react-toastify/dist/ReactToastify.min.css";
import history from "./history";

import { PersistGate } from "redux-persist/integration/react";

// import store
import store, { persistor } from "./store";

// import action
import { getAllProducts, refreshStore } from "./actions";

// import routes
import AppRoute from "./routes";

// import Utils
import { initFunctions } from "./utils";

import LoadingOverlay from "./components/features/loading-overlay";
import axios from "axios";
import { env } from "./env";

axios.defaults.baseURL = env.API_URL;
if (localStorage.getItem("userToken")) {
  let token = localStorage.getItem("userToken");
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
export function Root() {
  initFunctions();
  store.dispatch(getAllProducts());

  useEffect(() => {
    if (store.getState().modal.current !== 23) {
      store.dispatch(refreshStore(23));
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<LoadingOverlay />}>
        <BrowserRouter basename={"/"} history={history}>
          <AppRoute />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

ReactDOM.render(<Root />, document.getElementById("root"));
