import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import PageHeader from "../common/page-header";
import Breadcrumb from "../common/breadcrumb";
import {
  addToCart,
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
  filterSort,
} from "../../actions";

import axios from "axios";
import $ from "jquery";
import ProductCard from "../common/product-card";

function Search(props) {
  let { addToCart, toggleWishlist, addToCompare, showQuickViewModal } = props;
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);

  const [products, setProducts] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let params = {};
    if (query.has("q")) params.q = query.get("q");
    const response = await axios.get("products", {
      params,
    });
    setProducts(response.data.data);
    setLoading(false);
    $(".skel-shop-products").addClass("loaded");
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        className="App"
      >
        <div className="spinner-border text-primary" role="status" style={{width:"37px",height:"37px"}}></div>
      </div>
    );
  } else if (products.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
        className="App"
      >
        No Products Found With This Search
      </div>
    );
  }
  console.log("myAllProducts", products);
  return (
    <>
      <Helmet>
        <title>Molla React eCommerce Template | Shop With Sidebar</title>
      </Helmet>

      <div className="main">
        <PageHeader title="Search" subTitle={query.get("q")} />
        <Breadcrumb title="Search" adClass="mb-2" />
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="products mb-3">
                  <div className="row d-flex justify-content-center">
                    {products &&
                      products.map((item, index) => (
                        <div
                          className="col-6 col-md-4 col-lg-4 col-xl-3"
                          key={"product" + index}
                        >
                          <div className="skel-pro"></div>
                          <ProductCard
                            product={item}
                            type="sidebar"
                            onAddToCart={addToCart}
                            onToggleWishlist={toggleWishlist}
                            onAddToCompare={addToCompare}
                            showQuickView={showQuickViewModal}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  addToCart,
  toggleWishlist,
  addToCompare,
  showQuickViewModal,
  filterSort,
})(Search);
