import { GET_SLIDER } from "../constants/action-types";

let initialState = [];

const sliderReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SLIDER:
      return {
        ...state,
        sliderData: action.payload,
      };
      default:
        return state
  }
};

export default sliderReducer;
