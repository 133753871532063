import React from "react";

// import utils
import { safeContent } from "../../utils";

export default function HomeSlide(props) {
  const { image, sub_title, title, button_text } = props.data;

  return (
    <div
      className="intro-slide"
      style={{
        backgroundImage: `url(${image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="intro">
        <div
          className="title"
          dangerouslySetInnerHTML={safeContent(sub_title)}
        ></div>

        <div
          className="content"
          dangerouslySetInnerHTML={safeContent(title)}
        ></div>

        <div
          className="action"
          dangerouslySetInnerHTML={safeContent(button_text)}
        ></div>
      </div>
    </div>
  );
}
