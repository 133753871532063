import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import LoadingOverlay from "../components/features/loading-overlay";
import Search from "../components/Search/Search";
import SingleFwSidebar from "../components/pages/blogs/single-fw-sidebar";
import Layout from '../components/app';
import HomePage from '../components/home';

import { scrollTop } from "../utils";
// import blogDetailsWithSidebar from "../components/pages/blogs/blogDetailsWithSidebar";

const ElementPages = React.lazy(() => import("./elements-route.js"));
const ProductPages = React.lazy(() => import("./products-route.js"));
const ShopPages = React.lazy(() => import("./shop-route.js"));
const BlogPages = React.lazy(() => import("./blogs-route.js"));
const OtherPages = React.lazy(() => import("./others-route.js"));

export default function AppRoot() {
  useEffect(() => {
    scrollTop();
  }, []);

  return (
    <React.Suspense fallback={<LoadingOverlay />}>
      <Switch>
		<Layout>
			<Route exact path={ `/` } component={ HomePage } />
		
        <Route
          path={`${process.env.PUBLIC_URL}/elements`}
          component={ElementPages}
        />
   
        <Route
          path={`${process.env.PUBLIC_URL}/product`}
          component={ProductPages}
        />
        <Route path={`${process.env.PUBLIC_URL}/shop`} component={ShopPages} />
        <Route path={`${process.env.PUBLIC_URL}/blog`} component={BlogPages} />
        <Route
          path={`${process.env.PUBLIC_URL}/pages`}
          component={OtherPages}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/search`}
          component={Search}
        />

        <Route path={`${process.env.PUBLIC_URL}/`} component={HomePage} />
        {/* <Route exact path={`/blog/details/:id`} component={SingleFwSidebar} /> */}
		</Layout>
      </Switch>
    </React.Suspense>
  );
}
